import type { Motif, MotifComponentProps } from '@snapchat/snap-design-system-marketing';
import {
  Black,
  contrastYellowButtonVars,
  defaultCardColors,
  defaultMotif,
  dropdownMenuLightVars,
  m,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
  Plain,
  summaryCardVars,
  widthVars,
  Yellow,
} from '@snapchat/snap-design-system-marketing';

export const snapCarouselVars: MotifComponentProps<MotifComponent.CAROUSEL> = {
  ...defaultCardColors,
  ...widthVars,
  '--carousel-active-dot-color': Plain.BLACK,
  '--carousel-card-bg-color': Yellow.V100,
  '--carousel-card-hover-bg-color': Yellow.V50,
  '--carousel-card-border-color': Black.V50,
  '--carousel-card-border-width': '2px',
  '--carousel-card-hover-border-color': Black.V200,
  '--carousel-card-desktop-grid-gap': m('--spacing-xl'),
  '--carousel-card-box-shadow': 'none',
  '--carousel-card-hover-box-shadow': m('--box-shadow-m'),
};

export const snapSummaryCardVars: MotifComponentProps<MotifComponent.SUMMARY_CARD> = {
  ...summaryCardVars,
  '--summary-card-bg-color': Yellow.V100,
  '--summary-card-hover-bg-color': Yellow.V50,
  '--summary-card-border-radius': m('--border-radius-l'),
  '--summary-card-border-width': '2px',
  '--summary-card-border-color': Black.V50,
  '--summary-card-hover-border-color': Black.V200,
  '--summary-card-box-shadow': 'none',
  '--summary-card-hover-box-shadow': m('--box-shadow-m'),
};

export const snapButtonVars: MotifComponentProps<MotifComponent.BUTTON> = {
  ...contrastYellowButtonVars,
  '--button-border-width': '2px',
  '--button-hover-shadow': m('--box-shadow-l'),
  '--button-secondary-bg-color': Yellow.V100,
  '--button-secondary-fg-color': Plain.BLACK,
  '--button-secondary-border-color': Black.V50,
  '--button-secondary-hover-bg-color': Yellow.V50,
  '--button-secondary-hover-border-color': Plain.BLACK,
};

export const snapDropdownMenuVars: MotifComponentProps<MotifComponent.DROPDOWN_MENU> = {
  ...dropdownMenuLightVars,
  '--dropdown-button-border-radius': m('--border-radius-l'),
  '--dropdown-button-border-width': '2px',
  '--dropdown-button-border-color': Black.V50,
  '--dropdown-button-hover-border-color': Plain.BLACK,
};

/** Motif for the Snap.com homepage */
export const snapMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Snap',
  [MotifScheme.DEFAULT]: {
    name: 'Snap Yellow background.',
    [MotifComponent.BUTTON]: snapButtonVars,
    [MotifComponent.CAROUSEL]: snapCarouselVars,
    [MotifComponent.SUMMARY_CARD]: snapSummaryCardVars,
    [MotifComponent.DROPDOWN_MENU]: snapDropdownMenuVars,
  },
});
