import { css } from '@emotion/css';
import {
  largeDesktopMediaQuery,
  m,
  mobileMediaQuery,
  twoColumnCollapseMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const heroContainerCss = css`
  display: flex;
  flex-direction: row;
  gap: 0 ${m('--spacing-xxxxl')};
  max-width: 100%;

  ${twoColumnCollapseMediaQuery} {
    flex-wrap: wrap;
  }
`;

export const heroTitleCss = css`
  flex-grow: 2;
  padding: ${m('--spacing-xl')} 0;
  position: relative;

  > h1 {
    display: flex;
    flex-direction: column;
    min-width: 375px;
  }

  ${mobileMediaQuery} {
    display: block;
    width: 100%;

    > h1 {
      min-width: unset;
    }
  }
`;

export const ghostImageBaseCss = css`
  opacity: 0;
  position: absolute;
  /* Short animation to hide the ghost when user starts resizing */
  transition: opacity 0.25s ease-in-out;

  /* The following styles are to ensure consistent sizing for both image and video implementations */
  div.sdsm-media-wrapper {
    height: 100%;
  }

  img,
  video {
    height: 100%;
    margin: 0;
  }
`;

export const ghostImageVisibleCss = css`
  opacity: 1;
  /* Longer animation to show the ghost after user stops resizing */
  transition: opacity 2s ease-in-out;
`;

export const ghostImageTopCss = css`
  right: 0;
  top: 0;

  *[dir='rtl'] & {
    right: unset;
    left: 0;
  }
`;

export const ghostImageMiddleCss = css`
  right: 0;

  /* Horizontally center the media in the container.
     This is meant to fill the space to the side of the Hero text so the component is visually balanced. */
  img,
  video {
    margin: auto;
  }

  *[dir='rtl'] & {
    right: unset;
    left: 0;
  }
`;

export const ghostImageBottomCss = css`
  bottom: 0;
  right: 0;

  *[dir='rtl'] & {
    right: unset;
    left: 0;
  }
`;

export const heroBodyCss = css`
  min-width: 200px;
  padding: ${m('--spacing-xl')} 0;

  /* handle edge case (wide screen) where left container has a greater height than right container */
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 1em;
  }

  ${largeDesktopMediaQuery} {
    max-width: 50%;
  }
`;
