import { css, cx } from '@emotion/css';
import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';
import { snapHeaderBackgroundVar } from './styleConstants';

/** Hack for letting pages override the background color. */
const snapHeaderCss = css`
  background-color: var(${snapHeaderBackgroundVar});
  backdrop-filter: blur(8px);
`;

export const config: SiteConfiguration = {
  domainName: 'snap.com',
  globalNavProps: {
    defaultGroupKey: 'snap',
    className: cx(snapHeaderCss),
  },
  trackingSettings: {
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.WEB_COMMON },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleTagManagerId: 'GTM-W4W5H3K',
        googleAnalyticsId: 'G-LJY98QLWYT',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'snap',
        dsn: 'https://fdd828e30c9c423cb0653ae452415f77@sentry.sc-prod.net/81',
      },
    ],
  },
  csp: {
    connectSrc: [
      'https://newsroom.snap.com', // Newsroom block integration
    ],
  },
};
