import { css } from '@emotion/css';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

export const snapHomePageWrapperCss = css`
  display: flex;
  margin-top: calc(0px - var(${totalHeaderHeightCssVar}));
  min-height: 100vh;
  min-width: 100vw;
  padding-top: var(${totalHeaderHeightCssVar});
`;
